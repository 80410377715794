<template>
	<div :class="embed ? '':'py-3 px-5'">
		<v-col
				class="cell rounded-lg elevation-2 pa-0 mx-auto overflow-hidden"
				cols="12"
				style="position: relative"
				:lg="embed ? 12:11"
				:xl="embed ? 12:10"
				ref="child"
				:class="edit ? 'handle':''"
		>
			<v-row class="pa-0 mx-0" align="center" v-if="display !== 'form'">
				<v-col cols="auto" class="pr-1" v-if="embed && embed.icon">
					<v-icon size="20">{{ embed.icon }}</v-icon>
				</v-col>
				<v-col v-if="embed" class="pr-0" style="min-width: 50px">
					<div class="sub-title-2 font-weight-bold pt-1">{{ embed.label }}</div>
				</v-col>
				<v-spacer v-if="embed" />
				<v-col cols="auto" class="pr-0">
					<div class="rounded-lg elevation-2">
					
						<Button 
								v-if="routeType !== 'table' && Object.keys(fields).length > 0 && ready"
								:color="display === 'table' ? 'secondary' : 'cell2'" 
								tile 
								flat
								iconSize="16"
								:iconColor="display === 'table' ? 'white' : 'cell4'"
								:click="() => display = 'table'"
								iconVal="mdi-format-list-bulleted"
								iconPosition="center"
								small
								classes="rounded-l-lg"
						/>
						<v-divider vertical v-if="routeType === 'calendar' &&  Object.keys(fields).length > 0 && ready" />
						<Button 
								v-if="routeType === 'calendar' &&  Object.keys(fields).length > 0 && ready"
								:color="display === 'gantt' ? 'secondary' : 'cell2'" 
								:iconColor="display === 'gantt' ? 'white' : 'cell4'"
								:click="() => display = 'gantt'"
								iconVal="mdi-calendar"
								iconSize="16"
								tile
								flat
								small
						/>
						<v-divider vertical v-if="routeType === 'calendar' &&  Object.keys(fields).length > 0 && ready"/>
						<Button 
								v-if="routeType === 'calendar' &&  Object.keys(fields).length > 0 && ready"
								:color="display === 'calendar' ? 'secondary' : 'cell2'" 
								:iconColor="display === 'calendar' ? 'white' : 'cell4'"
								:click="() => display = 'calendar'"
								iconSize="16"
								tile
								flat
								small
								classes="rounded-r-lg"
								iconVal="mdi-calendar-month"
						/>
						<v-divider vertical v-if="routeType === 'file' &&  Object.keys(fields).length > 0 && ready" />
						<Button 
								v-if="routeType === 'file' &&  Object.keys(fields).length > 0 && ready"
								:color="display === 'file' ? 'secondary' : 'cell2'" 
								small
								iconSize="16"
								:iconColor="display === 'file' ? 'white' : 'cell4'"
								:click="() => display = 'file'"
								tile
								flat
								classes="rounded-r-lg"
								iconVal="mdi-folder"
						/>
					</div>
				</v-col>
				<v-spacer v-if="!embed" />
				<v-col cols="auto" v-if="routeChildren.filter(e => e.position === 'item_list').length > 0">
					<v-menu offset-y left rounded="lg">
						<template v-slot:activator="{ on }">
							<v-btn
								color="celldark"
								dark
								v-on="on"
								class="rounded-lg"
								small
								height="30"
								:loading="loading"
							>
								<v-row align="center" dense>
									<v-col style="max-width: 24px">
									</v-col>
									<v-col>
										<div class="paragraph font-weight-bold">{{ $t('single_trad.Table.config') }}</div>
									</v-col>
									<v-col class="pr-0" cols="auto">
										<v-icon>mdi-menu-down</v-icon>
									</v-col>
								</v-row>
							</v-btn>
						</template>
						<v-list class="rounded-lg">
							<v-list-item
								v-for="(btn, i) in routeChildren.filter(e => e.position === 'item_list')"
								:key="i"
								@click="() => { $router.push(computeLink(btn.endpoint)) }"
							>
								<v-list-item-icon class="mr-2">
									<v-icon small>{{ btn.icon }}</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<div class="caption-1 pr-2">{{ btn.label }}</div>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-menu>
				</v-col>

				<v-col cols="auto" v-if="ready" class="pl-0">
					<Actions
							:methods="methods"
							:actions="actions"
							:path="path"
							:display="display"
							@headers="() => events.notify('headers')"
					/>
				</v-col>
			</v-row>

			<v-row class="mx-0 pl-3" align="center" v-if="display !== 'form'">
				<v-col cols="auto" class="px-0 pt-0" v-if="methods.includes('create')">
					<Button color="primary" :click="() => $router.push('/dashboard/' + path + '/create')" :label="$t('global.action.new')" />
				</v-col>
				<v-col cols="auto" v-if="methods.includes('create')" class="pt-0">
					<v-divider vertical style="height: 29.5px" />
				</v-col>
				<v-col cols="auto" class="pr-0 pl-0 pt-0">
					<div class="rounded-lg">
						<Button
								v-if="methods.includes('create')"
								iconVal="mdi-plus"
								tile
								iconPosition="center"
								small
								color="primary"
								:toolTip="$t('single_trad.Dialog.fast_edit')"
								:click="() => {item2edit = {}; mode = 'creation'; edit_dialog = true }"
								classes="rounded-l-lg"
						/>
						<v-divider vertical v-if="methods.includes('create')" />
						<Button
								v-if="methods.includes('create')"
								iconVal="mdi-playlist-plus"
								tile
								iconPosition="center"
								small
								color="primary"
								:click="() => {$router.push('/dashboard/' + path + '/create_multiple')}"
								:toolTip="$t('ItemActions.create_multiple')"
						/>
						<v-divider vertical v-if="methods.includes('create') && routeType !== 'file'" />
						<Button
								v-if="methods.includes('create') && routeType !== 'file'"
								iconVal="mdi-content-copy"
								iconPosition="center"
								tile
								small
								color="secondary"
								:disabled="selected.length < 1"
								:click="selected.length === 1 ? () => {item2edit = selected[0]; mode = 'creation'; edit_dialog = true } : () => multipleClone()"
								:loading="loading"
								:classes="!methods.includes('destroy') ? 'rounded-r-lg':''"
								:toolTip="selected.length === 1 ? $t('single_trad.Table.clone') : $t('single_trad.Table.multiple_clone')"
						/>
						<v-divider vertical v-if="methods.includes('destroy')" />
						<Button
								v-if="methods.includes('destroy')"
								iconVal="mdi-delete"
								:tile="methods.includes('create')"
								iconPosition="center"
								small
								color="error"
								:disabled="selected.length < 1"
								:click="() => confirm_dialog = true"
								:loading="loading"
								classes="rounded-r-lg"
								:toolTip="$t('single_trad.Table.delete')"
						/>
					</div>
				</v-col>
				<v-col cols="auto" v-if="methods.includes('create') || methods.includes('destroy')" class="pt-0">
					<v-divider vertical style="height: 29.5px" />
				</v-col>
				<v-col class="pt-0 pl-0">
					<TextField
							style="min-width: 150px"
							v-model="search"
							:placeholder="$t('global.action.search')"
							appendIcon="mdi-magnify"
							:iconClick="() => events.notify('search', search)"
							@enter="() => events.notify('search', search)"
							clearable
							@clear="() => events.notify('search-clear')"
					/>
				</v-col>
			</v-row>
			<Calendar
					v-if="display === 'calendar' && ready"
					:fields="all_fields"
					:path="path"
					:actions="actions"
					:methods="methods"
					:pevents="events"
					:embed="false"
					:height="'500px'"
					:width="''"
					:routeChildren="routeChildren.filter(e => e.position === 'item_list')"
					class="rounded-lg overflow-hidden"
			/>
			<Table
					v-if="display === 'table' && Object.keys(fields).length > 0 && ready"
					:actions="actions"
					:methods="methods"
					:fields="fields"
					:ordering="ordering"
					:path="path"
					:allFields="all_fields"
					:pevents="events"
					:routeChildren="routeChildren.filter(e => e.position === 'item_list')"
					@headersChanged="$store.commit('profile/CHANGE_TABLE_HEADERS_VAL', {entity: $route.name, headers: Object.keys(fields)})"
					@ready="$emit('ready', $refs.child)"
			/>
			<Gantt 
				v-if="display === 'gantt' && Object.keys(fields).length > 0 && ready"
				:path="path"
				:methods="methods"
				:allFields="all_fields"
				:pevents="events"
				:routeParent="routeParent"
				:fields="fields"
			/>
			<FileBrowser
				v-if="display === 'file' && Object.keys(fields).length > 0 && ready"
				:path="path"
				:fields="fields"
				:allFields="all_fields"
				:actions="actions"
				:methods="methods"
				:pevents="events"
				:routeChildren="routeChildren.filter(e => e.position === 'item_list')"
			/>
			<Form v-if="display === 'form'" />
			<ComponentLoader v-if="!ready" :type="routeType" class="rounded-lg overflow-hidden"/>
			<div
					v-if="ready"
					class="overflow-hidden resizer"
					style="position: absolute; bottom: -4px; right: -4px; width: 40px; height: 40px; z-index: 1;"
					v-show="edit"
			>
				<v-hover v-slot:default="{ hover }">
					<div
							@mouseenter="(e) => $emit('resize', e)"
							class="text-center transition-cubic"
							:style="hover ? {backgroundColor: $tools.transparentize('black', 0.2, $vuetify)}:{}"
							style="position: relative; bottom: -4px; right: -15px;transform: rotateZ(45deg); height: 60px; display: flex; align-items: center; justify-content: start; cursor: nwse-resize"
					>
						<v-icon color="black2white" size="30" style="position: relative; top: 0px; left: -4px">mdi-chevron-right</v-icon>
					</div>
				</v-hover>
			</div>
		</v-col>

		<Dialog
				v-model="confirm_dialog"
				type="confirm"
				:message="item2delete === null ? selected.length === 1 ? $tc('single_trad.Table.dialog_v_delete', 1, {item: selected[0].display_name}) : $tc('single_trad.Table.dialog_v_delete', 2) : $tc('single_trad.Table.dialog_v_delete', 1, {item: item2delete ? item2delete.display_name : ''})"
				@cancel="confirm_dialog = false; item2delete = null"
				@confirm="item2delete === null ? selected.length === 1 ? deleteItem() : deleteItems() : deleteItem()"
				:loading="loading"
		/>

		<Dialog
				v-model="edit_dialog"
				:title="mode === 'fast-edition' ? $t('single_trad.Dialog.fast_edit') : $t('single_trad.Dialog.fast_create')"
				width="500"
				@close="events.notify('close-fast'); edit_dialog = false"
		>
			<template v-slot:actions v-if="mode === 'fast-edition'">
				<Button iconVal="mdi-eye" icon dark :click="() => $router.push('/dashboard/' + path + '/' + item2edit.id)" />
				<Button iconVal="mdi-delete" icon dark :click="() => { item2delete = item2edit; confirm_dialog = true }" />
			</template>
			<template v-slot:content>
				<div class="pa-8 text-center" v-if="loading">
					<v-progress-circular indeterminate size="40" color="primary"/>
				</div>
				<v-fade-transition v-else>
					<FieldGroup
							v-show="edit_dialog"
							:show="edit_dialog"
							class="px-3"
							v-model="item2edit"
							:fields="$tools.filterObject(form_fields, e => !e.read_only)"
							:mode="mode"
							:path="path"
							:pevents="events"
							:pathId="item2edit ? item2edit.id : ''"
							@newItemCreated="(e) => form_fields[e.field].choices.push(e.item)"
							@close="edit_dialog = false"
							@created="(e) => { events.notify('new-item', e); edit_dialog = false; $store.dispatch('snackbar/success', $tc('snackbar.FieldGroup.createAll.success', 1)) }"
							@saved="(e) => {events.notify('saved-item', e); $store.dispatch('snackbar/success', $tc('snackbar.EntityField.saveChanges.success', 1))}"
					/>
				</v-fade-transition>
			</template>
		</Dialog>
	</div>
</template>

<script>
	export default {
		name: "Set",
		props: ['routeType', 'routeParent', 'routeChildren', 'embed', 'parentItem', 'edit', 'pevents'],
		components: {
			Button: () => import("@/components/ui/Button.vue"),
			Calendar: () => import("@/components/Calendar.vue"),
			ComponentLoader: () => import('@/components/ui/ComponentLoader.vue'),
			FileBrowser: () => import('@/components/filebrowser/FileBrowser.vue'),
			Table: () => import('@/components/Table.vue'),
			Gantt: () => import('@/components/Gantt.vue'),
			Form: () => import('@/components/Form.vue'),
			Actions: () => import('@/components/Actions.vue'),
			TextField: () => import('@/components/fields/TextField.vue'),
			FieldGroup: () => import('@/components/FieldGroup.vue'),
			Dialog: () => import('@/components/ui/Dialog.vue')
		},
		data () {
			return {
				path: '',
				fields: {},
				search: '',
				all_fields: {},
				actions: [],
				ordering: [],
				ready: false,
				display:'',
				events: null,
				methods: [],
				selected: [],
				loading: false,
				item2edit: {},
				edit_dialog: false,
				mode: null,
				confirm_dialog: false,
				item2delete: null,
				form_fields: {}
			}
		},
		watch: {
			"$route"(to, from){
				if(to.name !== from.name){
					this.search = ''
					this.ready = false
					this.display = this.routeType
					this.actions = []
					this.setUpHooks()
					this.computePath()
					this.getFields()
				}
			},
			edit_dialog(val){
				if(val) {
					this.form_fields = {}

					let params = {...this.$route.params}

					if(this.item2edit.id) {
						if (this.embed) {
							params[this.embed.name] = this.item2edit.id
						}
						else {
							let endpoint = this.$route.path.replace('/dashboard/', '')
							let res = []

							for(let key of endpoint.split('/')){
								if(isNaN(parseInt(key))){
									res.push(key)
								}
							}

							res = res.join('')
							params[res] = this.item2edit.id
						}
					}

					this.loading = true

					this.$wsc.getOptions(params, this.parentItem, this.path, success => {
						this.form_fields = this.$tools.sortObject(success.fields, 'label')

						if (this.$store.getters['profile/table_headers'][this.embed ? this.embed.name : this.$route.name]) {
							let res = {}

							this.$store.getters['profile/table_headers'][this.embed ? this.embed.name : this.$route.name].forEach(e => {
								res[e] = success.fields[e]
							})

							this.fields = res
						} else {
							let res = []

							Object.keys(success.fields).forEach(e => {
								if (!success.fields[e].read_only || e === 'display_name') {
									if (e === 'display_name') {
										res.unshift(e)
									} else {
										res.push(e)
									}
								}
							})

							res = res.slice(0, 5)

							res.forEach(e => {
								this.form_fields[e] = success.fields[e]
							})
						}

						this.ordering = success.ordering

						if (success.actions.length > 0) {
							this.actions = success.actions
						}

						if (success.methods.length > 0) {
							this.methods = success.methods
						}

						this.loading = false

						this.ready = true
					}, fail => {
						this.loading = false
					})
				}
			}
		},
		methods: {
			deleteItem() {
				this.loading = true
				let item = {}

				if(this.item2delete === null){
					item = this.selected[0]
				}
				else {
					item = this.item2delete
				}

				this.$wsc.deleteItem(this.path, item.id, success => {
					this.events.notify('delete-item', success)
					this.confirm_dialog = false
					this.edit_dialog = false
					this.loading = false
					this.selected = []
				}, fail => {
					this.loading = false
				})
			},
			deleteItems() {
				this.loading = true

				let data = this.selected.map(e => e.id)

				this.$wsc.deleteBatch(this.path, data, success => {
					this.events.notify('delete-items', success)

					this.confirm_dialog = false
					this.loading = false
				}, fail => {
					this.loading = false
				})
			},
			multipleClone(){
				this.$store.commit('dashboard/CHANGE_MULTIPLE_ITEMS_VAL', this.selected)
				this.$router.push('/dashboard/' + this.path + '/create_multiple')
			},
			computeLink(endpoint) {
                let res = []

                if (endpoint) {
                    for (let i of endpoint.split('/')) {
                        if (i.match(':')) {
                            let vat = i.replace(':', '')
                            res.push(this.$route.params[vat])
                        } else {
                            res.push(i)
                        }
                    }
                }

                return '/dashboard/' + res.join('/')
            },
			getFields(){
				this.fields = {}
				this.all_fields = {}

				this.$wsc.getOptions(this.$route.params, this.parentItem, this.path, success => {
					this.all_fields = this.$tools.sortObject(success.fields, 'label')

					if(this.$store.getters['profile/table_headers'][this.embed ? this.embed.name : this.$route.name]){
						let res = {}

						this.$store.getters['profile/table_headers'][this.embed ? this.embed.name : this.$route.name].forEach(e => {
							res[e] = success.fields[e]
						})

						this.fields = res
					}
					else {
						let res = []

						Object.keys(success.fields).forEach(e => {
							if(!success.fields[e].read_only || e === 'display_name'){
								if(e === 'display_name'){
									res.unshift(e)
								}
								else {
									res.push(e)
								}
							}
						})

						res = res.slice(0, 5)

						res.forEach(e => {
							this.fields[e] = success.fields[e]
						})
					}

					this.ordering = success.ordering

					if(success.actions.length > 0) {
						this.actions = success.actions
					}

					if(success.methods.length > 0) {
						this.methods = success.methods
					}

					this.ready = true
				}, fail => {
				})
			},
			computePath(){
				if(this.routeParent !== null && this.embed) {
					let res = []

					for (let i of this.routeParent.split('/')) {
						if(i.match(':')) {
							res.push(this.$route.params[i.replace(':','')])
						}
						else {
							res.push(i)
						}
					}

					this.path = res.join('/')
				}
				else {
					this.path = this.$route.path.replace('/dashboard/', '')
				}
			},
			setUpHooks(){
				this.events = new this.$NVEvent("Set")

				this.events.wait('new-choice', (e) => {
					if(this.fields[e.field])
						this.fields[e.field].choices.push(e.item)
					this.all_fields[e.field].choices.push(e.item)
				})

				this.pevents.wait('update-app', () => {
					this.ready = false
					this.display = this.routeType
					this.computePath()
					this.getFields()
				})

				this.events.wait('fast-edit', (item) => {
					this.item2edit = item
					this.mode = 'fast-edition'

					this.$nextTick(() => {
						this.edit_dialog = true
					})
				})

				this.events.wait('fast-create', (item) => {
					this.item2edit = item
					this.mode = 'creation'

					this.$nextTick(() => {
						this.edit_dialog = true
					})
				})

				this.events.wait('selection', selected => {
					this.selected = selected
				})

				this.events.wait('delete-item-item', item => {
					this.item2delete = item
					this.confirm_dialog = true
				})
			}
		},
		created() {
			this.display = this.routeType
			this.search = this.$route.query.query_filter ? this.$route.query.query_filter : ''
			this.setUpHooks()
			this.computePath()
			this.getFields()
		},
		mounted(){
			this.$emit('loaded', this.$refs.child)
		}
	}
</script>

<style scoped>
	.handle {
		cursor: grab;
	}
	.handle > div:not(.resizer) {
		pointer-events: none;
	}
</style>
